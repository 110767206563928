import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Card,
	Row,
	Col,
	Table,
	Button,
	notification,
	Popconfirm,
	Checkbox,
	Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBan,
	faEdit,
	faEye,
	faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { DELETE, GET, POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";

const TblRow = ({ children, ...props }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: props["data-row-key"],
	});
	const style = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && {
				...transform,
				scaleY: 1,
			}
		),
		transition,
		...(isDragging
			? {
					position: "relative",
					zIndex: 9999,
			  }
			: {}),
	};
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if (child.key === "sort") {
					return React.cloneElement(child, {
						children: (
							<FontAwesomeIcon
								icon={faBars}
								ref={setActivatorNodeRef}
								style={{
									touchAction: "none",
									cursor: "move",
								}}
								{...listeners}
							/>
						),
					});
				}
				return child;
			})}
		</tr>
	);
};

export default function TableQuestionCategory(props) {
	const { form_id } = props;

	const navigate = useNavigate();

	const [dataSource, setDataSource] = useState([]);

	const { refetch: refetchSource } = GET(
		`api/form_question_category?form_id=${form_id}`,
		"form_question_category",
		(res) =>
			setDataSource(res.data.map((item, index) => ({ ...item, key: index })))
	);

	const { mutate: mutateChangeStatus, isLoading: isLoadingChangeStatus } = POST(
		"api/form_question_category_change_status",
		"form_question_category"
	);

	const handleChangeStatus = (values) => {
		mutateChangeStatus(values, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Question Category",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Question Category",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const { mutate: mutateDelete, isLoading: isLoadingDelete } = DELETE(
		"api/form_question_category",
		"form_question_category"
	);

	const handleDelete = (values) => {
		mutateDelete(values, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Question Category",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Question Category",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		refetchSource();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form_id]);

	const { mutate: mutateSort } = POST(
		"api/form_question_category_order",
		"form_question_category"
	);

	const onDragEnd = ({ active, over }) => {
		if (active.id !== over?.id) {
			let dataSourceTemp = [...dataSource];

			const activeIndex = dataSourceTemp.findIndex((i) => i.key === active.id);
			const overIndex = dataSourceTemp.findIndex((i) => i.key === over?.id);
			let data = arrayMove(dataSourceTemp, activeIndex, overIndex);
			setDataSource(data);

			mutateSort(
				{ data },
				{
					onSuccess: (res) => {
						if (res.success) {
							notification.success({
								message: "Question Category",
								description: res.message,
							});
						} else {
							notification.error({
								message: "Question Category",
								description: res.message,
							});
						}
					},
					onError: (err) => {
						notificationErrors(err);
					},
				}
			);
		}
	};

	const columns = [
		{
			key: "sort",
			title: "Sort",
			width: 50,
		},
		{
			title: "Action",
			align: "center",
			width: 250,
			render: (text, record) => (
				<>
					<Button
						type="link"
						className="btn-view"
						onClick={() =>
							navigate(`/evaluation/view/${form_id}/category/view/${record.id}`)
						}
						icon={<FontAwesomeIcon icon={faEye} />}
					/>

					<Button
						type="link"
						className="btn-edit"
						onClick={() =>
							navigate(`/evaluation/view/${form_id}/category/edit/${record.id}`)
						}
						icon={<FontAwesomeIcon icon={faEdit} />}
					/>

					<Popconfirm
						title="Are you sure to delete this data?"
						onConfirm={() => handleDelete(record)}
						onCancel={() => {
							notification.error({
								message: "Question Category",
								description: "Data not delete",
							});
						}}
						okText="Yes"
						cancelText="No"
					>
						<Button
							type="link"
							className="btn-delete"
							loading={isLoadingDelete}
							icon={<FontAwesomeIcon icon={faBan} />}
						/>
					</Popconfirm>
				</>
			),
		},
		{
			title: "Category",
			dataIndex: "category",
			render: (text, record) => (
				<span
					className="quill-output main-primary-color"
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				/>
			),
		},
		{
			title: "Visibility",
			dataIndex: "status",
			align: "center",
			render: (_, record) => (
				<Popconfirm
					title={`Are you sure to change the status of this data?`}
					onConfirm={() => handleChangeStatus(record)}
					onCancel={() => {
						notification.error({
							message: "Evaluation",
							description: "Data status not change",
						});
					}}
					okText="Yes"
					cancelText="No"
				>
					<Checkbox
						className="btn-status"
						checked={record.status === 1 ? true : false}
						disabled={isLoadingChangeStatus}
					>
						{record.status === 1 ? "Visible" : "Not Visible"}
					</Checkbox>
				</Popconfirm>
			),
		},
	];

	return (
		<Card
			title={
				<>
					<Typography.Text>Category List</Typography.Text>
				</>
			}
			extra={
				<Button
					className="btn-main-primary ml-10"
					icon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() => navigate(`/evaluation/view/${form_id}/category/add`)}
				>
					Add Category
				</Button>
			}
		>
			<Row gutter={[12, 12]} id="tbl_wrapper">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
					<DndContext
						modifiers={[restrictToVerticalAxis]}
						onDragEnd={onDragEnd}
					>
						<SortableContext
							// rowKey array
							items={dataSource.map((i) => i.key)}
							strategy={verticalListSortingStrategy}
						>
							<Table
								components={{
									body: {
										row: TblRow,
									},
								}}
								dataSource={dataSource}
								rowKey="key"
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								className="tbl-main-primary"
								columns={columns}
							/>
						</SortableContext>
					</DndContext>
				</Col>
			</Row>
		</Card>
	);
}
