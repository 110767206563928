import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Button, Form, Modal, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import validateRules from "../../../../providers/validateRules";
import FloatSelect from "../../../../providers/FloatSelect";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalForm(props) {
	const {
		toggleModalForm,
		setToggleModalForm,
		dataUserRoles,
		dataSchoolYear,
		dataSemester,
	} = props;

	const navigate = useNavigate();

	const [form] = Form.useForm();

	const { mutate: mutateForm, isLoading: isLoadingForm } = POST(
		"api/form",
		"form_list"
	);

	const onFinish = (values) => {
		let data = {
			...values,
			id: toggleModalForm.data ? toggleModalForm.data.id : "",
		};
		mutateForm(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Evaluation",
						description: res.message,
					});
					if (toggleModalForm.data && toggleModalForm.data.id) {
						setToggleModalForm({
							open: false,
							data: null,
						});
					} else {
						navigate(`/evaluation/view/${res.id}/category/add`);
					}
				} else {
					notification.error({
						message: "Evaluation",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		if (toggleModalForm.open) {
			if (toggleModalForm.data) {
				form.setFieldsValue({
					form_name: toggleModalForm.data.form_name,
					semester_id: toggleModalForm.data.semester_id
						? Number(toggleModalForm.data.semester_id)
						: null,
					school_year_id: toggleModalForm.data.school_year_id
						? Number(toggleModalForm.data.school_year_id)
						: null,
					type: toggleModalForm.data.type,
					user_role_ids: toggleModalForm.data.form_user_roles.reduce((a, b) => {
						return [...a, b.user_role_id];
					}, []),
				});
			} else {
				form.setFieldValue("year", "");
			}
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalForm]);

	return (
		<Modal
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			title="Evaluation Form"
			open={toggleModalForm.open}
			footer={[
				<Button
					key={0}
					className="btn-main-primary"
					loading={isLoadingForm}
					onClick={() => form.submit()}
				>
					SUBMIT
				</Button>,
			]}
			onCancel={() => {
				setToggleModalForm({
					open: false,
					data: null,
				});

				form.resetFields();
			}}
			forceRender
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="form_name" rules={[validateRules.required()]}>
					<FloatInput
						label="Evaluation Name"
						placeholder="Evaluation Name"
						required
					/>
				</Form.Item>

				<Form.Item name="school_year_id" rules={[validateRules.required()]}>
					<FloatSelect
						label="School Year"
						placeholder="School Year"
						required
						options={
							dataSchoolYear && dataSchoolYear.data
								? dataSchoolYear.data.map((item) => ({
										value: item.id,
										label: item.sy_from + " - " + item.sy_to,
								  }))
								: []
						}
					/>
				</Form.Item>

				<Form.Item name="semester_id" rules={[validateRules.required()]}>
					<FloatSelect
						label="Semester"
						placeholder="Semester"
						required
						options={
							dataSemester && dataSemester.data
								? dataSemester.data.map((item) => ({
										value: item.id,
										label: item.semester,
								  }))
								: []
						}
					/>
				</Form.Item>

				<Form.Item name="type" rules={[validateRules.required()]}>
					<FloatSelect
						label="Type"
						placeholder="Type"
						required
						options={[
							{
								value: "All",
								label: "All",
							},
							{
								value: "Faculty",
								label: "Faculty",
							},
							{
								value: "Student",
								label: "Student",
							},
						]}
					/>
				</Form.Item>

				<Form.Item name="user_role_ids" rules={[validateRules.required()]}>
					<FloatSelect
						label="For Role"
						placeholder="For Role"
						required
						multi="multiple"
						options={
							dataUserRoles && dataUserRoles.data
								? dataUserRoles.data.map((item) => ({
										value: item.id,
										label: item.role,
								  }))
								: []
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
